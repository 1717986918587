const specialOffers = [
	{
		id: 1,
		dateRange: '2025',
		items: [
			{
				name: 'HO-YA Sriracha Extra Hot Sauce',
				image: '/assets/hoyasri.jpg',
				price: '$5.99/BOTTLE',
			},
			{
				name: '中国虾仁 21-25 WHT SHRIMP HEADLESS PEELED TAIL-OFF 10x4LB',
				image: '/assets/oc2125.jpeg',
				price: '$189.00/CS',
			},
			{
				name: '中国虾仁 26-30 WHT SHRIMP HEADLESS PEELED TAIL-OFF 10x4LB',
				image: '/assets/china.jpg',
				price: '$185.00/CS',
			},
			{
				name: '中国虾仁 51-60 WHT SHRIMP HEADLESS PEELED TAIL-OFF 10x4LB',
				image: '/assets/51-60CH2.jpeg',
				price: '$178.00/CS',
			},
			{
				name: '中国虾仁 61-70 WHT SHRIMP HEADLESS PEELED TAIL-OFF 10x4LB',
				image: '/assets/china.jpg',
				price: '$175.00/CS',
			},
			{
				name: '中国 优质尤魚筒 Frozen squid tube U5',
				image: '/assets/OTsquid3.jpeg',
				price: '$85.00/CS',
			},
			{
				name: '中国 优质尤魚筒 Frozen squid tube U10',
				image: '/assets/OTsquid3.jpeg',
				price: '$85.00/CS',
			},
			{
				name: 'Basa Fillet 1x10KG',
				image: '/assets/pangafillet.jpeg',
				price: '$55.00/CS',
			},
			{
				name: '印度 有尾白虾仁 21-25 WHT SHR PDTO 5x2LB',
				image: '/assets/OT-IN21-25.jpeg',
				price: '$75.00/CS',
			},
			{
				name: '印度 有尾白虾仁 26-30 WHT SHR PDTO 5x2LB',
				image: '/assets/OT-IN-26-30.jpeg',
				price: '$64.95/CS',
			},
			{
				name: '印度 有尾白虾仁 16-20 WHT SHR PDTO 5x2LB',
				image: '/assets/Ot-IN-16-20.jpeg',
				price: '$78.65/CS',
			},
			{
				name: '10-20 CA IQF SCALLOP 2X5LB',
				image: '/assets/1020scallop.jpg',
				price: '$229.00/CS',
			},
			{
				name: '泰国 無头白虾 16-20 WHT SHR HEADLESS SHELL-ON 6x4LB',
				image: '/assets/OCPWS1620v2.jpeg',
				price: '$188.00/CS',
			},
			{
				name: '泰国 無头白虾 13-15 WHT SHR HEADLESS SHELL-ON 6x4LB',
				image: '/assets/thailand.png',
				price: '$238.00/CS',
			},
			{
				name: '印度 無头白虾 21-25 WHT SHR HEADLESS 6x4LB',
				image: '/assets/india.jpg',
				price: '$148.00/CS',
			},
			{
				name: '南美有头白虾 40-50 WHT SHR HEAD-ON 6x4LB',
				image: '/assets/ecuador.jpg',
				price: '$94.00/CS',
			},
			{
				name: '泰国 软売蟹 100-150 TH SO SHELL Crab 10x1KG',
				image: '/assets/crab_100.jpg',
				price: '$285.00/CS',
			},
			{
				name: '加拿大 多春鱼 61-65 CA FEM CAPELIN 9kg',
				image: '/assets/OTcapelin3.jpeg',
				price: '$3.25/LB',
			},
			{
				name: '中国 金仓鱼400-600 VP POMPANO 1X40LB',
				image: '/assets/pompano.jpg',
				price: '$3.85/LB',
			},
			{
				name: '牛肺 Beef Lung Frozen',
				image: '/assets/beeflung.webp',
				price: '$2.38/LB',
			},
			{
				name: 'AA Flank Steak Frozen',
				image: '/assets/GGflanksteak.jpeg',
				price: '$15.75/KG',
			},
			{
				name: '鸡爪 Chicken Feet Frozen 1x15KG',
				image: '/assets/chickenfeet.jpeg',
				price: '$3.35/LB',
			},
			{
				name: '鸡翅 Chicken wing medium',
				image: '/assets/chickwing.webp',
				price: '$6.75/KG',
			},
			{
				name: '排骨 Pork Spare ribs',
				image: '/assets/GGporkribs.jpeg',
				price: '$2.50/LB',
			},
			{
				name: '猪肚 Pork Stomach #1 Frozen',
				image: '/assets/porkstomach.webp',
				price: '$2.65/LB',
			},
			{
				name: '猪大肠 Rectum (Bung) Pork Frozen',
				image: '/assets/porkbung.webp',
				price: '$8.5/LB',
			},
		],
	},
];

export default specialOffers;
